import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiModule } from '@tytapp/api';
import { TYTCommonModule } from '@tytapp/common';
import { TopicsService } from './topics.service';

@NgModule({
    imports: [
        CommonModule,
        TYTCommonModule,
        ApiModule
    ],
    providers: [
        TopicsService
    ]
})
export class TopicsModule {
}